<template>
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.45711 14.9571L4.04289 13.5429L9.83579 7.75L4.04289 1.95711L5.45711 0.542894L12.6642 7.75L5.45711 14.9571Z"
      fill="currentColor"
    />
  </svg>
</template>
